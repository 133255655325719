.clAvatar {
    color: #FFFFFF;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.clAvatar.margin {
    margin: 16px
}
.clAvatar.positionAbsolute{
    position: absolute !important;
}
.clAvatar.header{
    cursor: pointer;
}
.clAvatar.pro{
    border: 2px solid #fbbc04;
    box-sizing: border-box;
}
@media (min-width: 769px){
    .clAvatar.header{
        margin-left: 30px;
    }
}