.jobDescription{
	padding: 4px 0 12px 0;
    white-space: pre-line;
    overflow: auto;
    border-bottom: 1px solid #ebebeb;
}
.jobDescription .text{
	font-size: 14px;
    line-height: 1.5em;
    color: rgba(0,0,0,0.87);
}
.jobDescription.rtl{
    direction: rtl;
}
.jobDescription.ltr{
    direction: ltr;
}
.readMore {
    color: #4285f4;
    font-size: 14px;
    margin-top: 18px;
    cursor: pointer;
    display: flex;
}
.readMore .icon{
    height: 18px;
    line-height: 0;
    width: 18px;
    margin-left: 8px;
    display: block;
    fill: currentColor;
    position: relative;
}