.modal-content{
    max-width: 216px;
}
.shareButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.shareIcon {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex-align: center;
    cursor: pointer;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
    border-radius: 50%;
    background-clip: padding-box;
}
.modalTitle{
	color: rgba(0,0,0,.87);
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 19px;
}
.urlToShareContainer{
	display: block;
	position: relative;
	margin: 0 3px;
}
#urlToShare{
	padding: 4px 0;
	color: rgba(0,0,0,.87);
	font-size: 16px;
	line-height: 18px;
	width: 100%;
	cursor: pointer;
	border: none;
	border-bottom: 1px solid rgba(0,0,0,.12);
	border-radius: 0;
	margin: 0;
}
#urlToShare:focus{
	outline: none;
	border-bottom: 2px solid #5677fc;
}
.labelUrlToShare{
	cursor: pointer;
    color: rgba(0,0,0,.54);
    font-size: 14px;
    line-height: 16px;
    padding-top: 11px;
    padding-bottom: 7px;
}
.shareDialogActions{
    padding: 8px 24px 16px !important;
}