@media (max-width: 599px){
    .matchDialogPaper{
        padding: 0 8px;
    }

    .matchTitleShadow {
    font-style: italic;
    clip-path: polygon(0% 55%, 100% 55%, 100% 100%, 0 100%);
    margin-top: -27.25px;
    }

    .blueShadow{
        margin-right: 11px;
    }
    
    .lightBlueShadow{
        margin-right: 24px;
    }
}

@media (min-width: 600px){
    .matchDialogPaper{
        padding: 0 32px;
    }

    .matchTitleShadow {
        font-style: italic;
        clip-path: polygon(0% 55%, 100% 55%, 100% 100%, 0 100%);
        margin-top: -36px;
    }

    
    .blueShadow{
        margin-right: 15px;
    }
    
    .lightBlueShadow{
        margin-right: 30px;
    }
}

@keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(-20px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 } 

.matchTitle{
    font-style: italic;
}

.blueShadow{
    text-shadow: -1px -1px 0 rgb(75, 150, 150, 0.8), 1px -1px 0 rgb(75, 150, 150, 0.8), -1px 1px 0 rgb(75, 150, 150, 0.6), 1px 1px 0 rgb(75, 150, 150, 0.8);
    z-index: -1;
    animation-duration: 1s;
    animation-name: fadeInDown;
}

.lightBlueShadow{
    text-shadow: -1px -1px 0 rgb(75, 150, 150, 0.4), 1px -1px 0 rgba(75, 150, 150, 0.4), -1px 1px 0 rgb(75, 150, 150, 0.4), 1px 1px 0 rgb(75, 150, 150, 0.4);
    z-index: -1;
    animation-duration: 2s;
    animation-name: fadeInDown;
}

.deleteAlertDialogPaper {
    padding: 20px;
}

.matchItem {
    text-decoration: underline;
}

.matchDialogPaper {
    text-align: center;
    width: 100%;
    max-width: 550px !important;
    overflow: hidden;
    margin: 8px !important;
    max-height: 650px !important;
    height: calc(100% - 16px) !important;
    box-sizing: border-box;
}

.matchDialogContainer {
    width: 100%;
    margin: 0 auto;
}

.matchCloseButton {
    margin: 0 36px 10px
}

.matchDialogLines{
    white-space: nowrap;
    text-transform: capitalize;
    color: rgba(0, 0, 0);
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 10px;
}
