.histogramFooter {
    border-top: 1px solid rgba(0,0,0,.10);
}
.histogramFooterLinks {
    padding: 16px;
    font-size: 14px;
    line-height: 1.2;
}

.histogramFooterLinks .label {
    margin-bottom: 6px;
    color: #222;
}

.histogramFooterLinks .links {
    line-height: 1.8;
    text-align: center;
    margin: 0px 15px;
    width: 100%;
    word-wrap: break-word;
}

.histogramFooterLinks .links a{
    color: #396a92;
    text-transform: capitalize;
    white-space: pre-wrap;
}
.histogramFooter .links div {
    display: inline-block;
}
@media (max-width: 768px){
    .histogramFooterLinks .links a{
        margin-right: 15px;
    }
}
@media (min-width: 769px){
    .histogramFooterLinks .links a{
        margin-right: 10px;
    }
}
