@media (max-width: 768px){
	.card{
		width:100%;
        padding-top: 54px;
    }
    .cardHeader, .cardFooter {
		padding: 24px;
    }
    .cardHeader, .jobHuntUsersCardHeader{
        height: 245px;
        position: relative;
        box-sizing: border-box;
    }
    .cardHeaderContent {
        position: absolute;
        bottom: 24px;
        left: 24px;
        right: 24px;
    }
    .jobTitleSection .shareButton{
        position: fixed;
        top: 0;
        z-index: 200;
        padding: 15px 24px 16px 0;
        color: #fff;
        transition-duration: 0.3s;
        transition-property: opacity;
        display: inline-block;
        opacity: 1;
        vertical-align: middle;
    }
    .jobTitleSection .shareButton{
        right: 0;
    }

    .shareButton:hover{
        color: rgba(0,0,0,.87);
        cursor: pointer;
    }
    .jobTitle{
        max-height: 3.3000000715255737em;
        font-size: 28px;
        line-height: 1.100000023841858em;
        width: 100%;
    }
	.jobTitleSection, .jobCompany, .jobLocation{
		color: white;
    }
    .cardLogo{
		box-shadow: 0 2px 8px 0 rgba(0,0,0,0.20);
    }
    .cardLogo img{
        max-width: 38px;
        max-height: 38px;
    }
    .apply.onTop {
        position: fixed;
        top: 0;
        z-index: 150;
        margin: 10px 0;
        vertical-align: middle;
        right: 65px;
        
    }
    .apply.onTop button{
        color: white !important;
        border-color: white !important;
        height: 36px !important;
    }
    .applySection{
        box-sizing: border-box;
    }
    .applySection .buttonContainer{
        min-height: 36px
    }
    .card.empty{
        padding: 25px;
        height: 100%;
    }
    .jobBadge{
        text-align: center;
        margin-top: 16px;
        padding-bottom: 16px;
    }
    .jobBadge .name, .jobBadge .connectAnyway{
        display: block;
        width: fit-content;
        margin: auto;
        margin-bottom: 8px;
        padding: 4px 12px;
    }
    .jobBadge .connectAnyway{
        margin-top: 8px;
    }
    .applyButton.orphan{
        min-width: 152px;
        max-width: 180px;
    }
    .bookmarkIconContentText{
        position: fixed;
        top: 0;
        z-index: 150;
        padding: 10px 40px 16px 0;
        margin-right: 20px;
        color: #fff;
        transition-duration: 0.3s;
        transition-property: opacity;
        display: inline-block;
        opacity: 1;
        vertical-align: middle;
        right: 0;
        font-size: 14px;
        line-height: 40px;
    }
    .bookmarkIconContent{
        position: fixed;
        top: 0;
        z-index: 150;
        padding: 10px 90px 16px 0;
        color: #fff;
        transition-duration: 0.3s;
        transition-property: opacity;
        display: inline-block;
        opacity: 1;
        vertical-align: middle;
        right: 0;
        font-size: 15px;
        line-height: 50px;
    }
}
@media (min-width: 769px){
    .cardLogo img{
        max-width: 38px;
        max-height: 38px;
    }
    .card{
		/*width: -webkit-calc(100% - 420px);
		width: calc(100% - 420px);*/
        width: 100%;
		margin: 16px 16px 4px 16px;
		/*max-width: 1027px;*/
        overflow-y: auto;
        min-height: -webkit-calc(100% - 36px);
        min-height: calc(100% - 36px);
    }
    .cardHeader, .cardFooter{
		padding: 32px;
    }
    .jobHuntUsersCardHeader{
        padding: 24px;
    }
    .jobTitleSection .shareButton{
		color: rgba(0,0,0,.54);
		display: inline-block;
		padding-top: 4px;
		position: absolute;
        vertical-align: middle;
        cursor: pointer;
        z-index: 1;
        top: 4px
    }
    .jobTitleSection .shareButton.rtl{
        left: 0;
    }
    .jobTitleSection .shareButton.ltr{
        right: 0;
    }
    .jobTitle{
        max-height: 2.299999952316284em;
        font-size: 30px;
        line-height: 1.149999976158142em;
        width: 80%;
    }
    .jobTitleSection {
		color: #222;
    }
    .jobLocation, .jobCompany{
		color: rgba(0, 0, 0, 0.54);
    }
    .jobInfoSection {
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 32px;
    }
    .card.empty{
        padding-top: 150px;
    }
    .jobBadge{
        text-align: left;
        margin-top: 8px;
        padding-bottom: 8px;
        line-height: 2;
    }
    .jobBadge .name, .jobBadge .connectAnyway{
        padding: 5px 8px;
    }
    .jobBadge .name{
        margin-right: 12px;
    }
    .jobBadge .connectAnyway{
        display: inline-block;
        margin-right: 6px;
    }
    .jobTitleSection .bookmarkIconContentText{
		color: rgba(0,0,0,.54);
		display: inline-block;
		position: absolute;
        vertical-align: middle;
        cursor: pointer;
        z-index: 1;
        font-size: 12px;
        line-height: 40px;
    }
    .bookmarkIconContent.ltr{
        margin-right: 85px;
        right: 0;
    }
    .bookmarkIconContent.rtl{
        margin-left: 75px;
        left: 0;
    }
    .bookmarkIconContent{
		color: rgba(0,0,0,.54);
		display: inline-block;
		position: absolute;
        vertical-align: middle;
        cursor: pointer;
        z-index: 1;
        top: 0;
        line-height: 54px;
    }
}
.jobBadge{
    font-size: 14px;
    direction: ltr;
    border-bottom: 1px solid #ebebeb;
}
.jobBadge .name, .jobBadge .connectAnyway{
    border-radius: 12px;
    font-weight: 700;
    line-height: 1;
}
.jobBadge .name{
    background-color: #ea7600;
    color: #fff;
    cursor: default;
}
.jobBadge .connectAnyway{
    background-color: #fbbc04;
    color:black;
    cursor: pointer;
}
.expiredBadge .name{
    background-color: #db4437;
}
.card{
	border-radius: 2px;
    box-shadow: 0 0 2px rgba(0,0,0,0.2);
    background-color: #fff;
	display: inline-block;
    vertical-align: top;
}
.card.rtl{
	direction:rtl;
}
.cardHeader, .jobHuntUsersCardHeader{
	padding-bottom:0;
}
.cardFooter{
	padding-top:0;
}
.jobTitleSection{
	margin-bottom: 12px;
    position: relative;
}
.jobTitle{
	overflow: hidden;
    position: relative;
    white-space: normal;
    text-overflow: ellipsis;
    font-weight: 700;
    text-transform: capitalize; 
}
.jobCompany{
    margin: 0 0 4px 0;
}
.jobLocation, .jobCompany{
	font-size: 14px;
    line-height: 1.25em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
}
.cardLogo{
	width: 40px;
    height: 40px;
    color: #FFFFFF;
    display: inline-block;
    position: absolute;
    text-align: center;
    font-size: 20px;
    line-height: 2em;
    border: 1px solid rgba(0,0,0,0.10);
    box-sizing: border-box;
    border-radius: 4px;
    cursor: default;
    background-color: white;
}
.cardLogo img{
    border-radius: 4px;
}
.jobInfoSection{
    position: relative;
}
.applySection{
    border-bottom: 1px solid #ebebeb;
    padding: 16px 0;
}
.jobInfoSectionInner{
	display: inline-block;
}
.jobInfoSectionInner.rtl{
    margin-right: 50px;
}
.jobInfoSectionInner.ltr{
    margin-left: 50px;
}

.jobMoreInfoSection{
	margin: 16px 0;
    font-size: 14px;  
}
.jobMoreInfoSection{
	color: rgba(0, 0, 0, 0.54);
}
.jobMoreInfoSection .jobMoreInfo{
	border: 1px solid rgba(0,0,0,0.05);
    padding: 4px;
    direction: ltr;
}
.jobMoreInfo{
	display: inline-block;
}
.jobMoreInfo.rtl{
    margin-left: 8px;
}
.jobMoreInfo.ltr{
    margin-right: 8px;
}
.jobMoreInfo .jobMoreInfoIcon{
	height: 18px;
    line-height: 18px;
    width: 18px;
    display: inline-block;
    fill: currentColor;
    position: relative;
    vertical-align: middle;
}
.jobMoreInfo.rtl .jobMoreInfoIcon{
    padding-right: 4px;
}
.jobMoreInfo.ltr .jobMoreInfoIcon{
    padding-right: 4px;
}
.jobMoreInfo .jobMoreInfoText{
	vertical-align: middle;
}
.cardFooterLink{
	width: 100%;
    z-index: 0;
    cursor: pointer;
    position: relative;
	border-bottom: 1px solid #ebebeb;
}
.cardFooterLink .icon{
    padding: 16px 0;
    z-index: -1;
    position: absolute;
    top: -3px;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    line-height: 24px;
    width: 24px;
    color: rgba(0, 0, 0, 0.54);
}
.cardFooterLink .link{
	width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    color: rgba(0,0,0,0.87);
    display: inline-block;
    overflow: hidden;
    padding: 16px 36px 16px 56px;
    text-overflow: ellipsis;
    text-decoration: none;
}
.cardFooterLink .arrow {
    padding: 16px 0;
    z-index: -1;
    top: -3px;
    color: rgba(0, 0, 0, 0.54);
    display: inline-block;
    fill: currentColor;
    height: 24px;
    line-height: 24px;
    position: absolute;
    width: 24px;
}
.cardFooterLink.ltr .icon{
    left: 0;
}
.cardFooterLink.rtl .icon{
    right: 0;
}
.cardFooterLink.ltr .link{
    padding: 16px 36px 16px 56px;
}
.cardFooterLink.rtl .link{
    padding: 16px 56px 16px 36px;
}
.cardFooterLink.ltr .arrow{
    right: 0;
}
.cardFooterLink.rtl .arrow{
    left: 0;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.tagsSection {
    border-bottom: 1px solid #ebebeb;
    padding: 16px 0px 12px;
}
.card.empty{
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
}
.card.empty .icon{
    font-size: 80px;
    padding: 10px;
    color: #737373;
    text-align: center;    
}
.modalOption{
    text-align: left;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.reportButton{
    direction: ltr !important;
    margin-top: 8px !important;
    color:#757575 !important;
    padding-left: 0;
    padding-right: 0;
}
.reportButtonReview .icon{
    color:#757575 !important;
    font-size:18px;
    transform:rotate(335deg);
}
.reportButton .icon{
    margin-right:4px;
    font-size:18px;
    transform:rotate(335deg);
}
.reportButton .label{
    text-transform:capitalize;
    color: rgba(0,0,0,0.87);
}