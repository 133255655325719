.histAlert{
    background-color: #fafafa;
    outline: 0;
    display: inline-block;
    padding: 0 0 0 0;
    vertical-align: top;
    width: 100%;
    position: relative;
    border-top: 1px solid rgba(0,0,0,.10);
    
}
.histAlert .alert{
    margin: 8px 16px;
    padding: 8px;
    background-color: #e6f8ff;
    line-height: 22px;
    border-radius: 3px;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.1), 0 0 2px 0 rgba(0,0,0,0.2)
}
.clickableText{
    cursor: pointer;
    color: #1861bf;
}

.closeIcon{
    position: absolute !important;
    top: 8px;
    right: 16px;
}