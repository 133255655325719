.tag{
	margin-left: 0;
    margin-right: 4px;
    background-color: #FFFFFF;
    border: 1px solid #DFE1E5;
    color: #3C4043;
    border-radius: 32px;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    line-height: 32px;
    margin: 3px;
    outline: 0;
    overflow: hidden;
    padding: 0 12px;
    white-space: nowrap;
    text-transform: capitalize;
}

.tag.selected{
    background-color: #E8F0FE;;
    border: 1px solid #D2E3FC;
    color: #396a92;
}

.tag.rect{
    border-radius: 3px;
}