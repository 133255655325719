@media (min-width: 769px){
    .contactSection {
        border-bottom: 1px solid #ebebeb;
        padding: 16px 0;
        margin: 0 !important;
    }
    .contactLink, .contactLink .link {
        color: rgba(0, 0, 0, 0.54);
    }
    .addBadge{
        border: 1px solid #396a92;
    }
}
@media (max-width: 769px){
    .contactLink, .contactLink .link {
        color: white;
    }

    .contactButton{
        box-shadow: 0 2px 8px 0 rgba(0,0,0,0.20) !important;
    }
}

.contactButton{
    width: 30px !important;
    height: 30px !important;
    min-height: 25px !important;
    margin-top: 8px !important;
}

.contactButton.ltr{
    margin-right: 16px !important;
}

.contactButton.rtl{
    margin-left: 16px !important;
}

.contactButton .icon{
    height: 15px;
}

.contactLink{
    display: flex;
    direction: ltr;
    width: fit-content;
    margin-top: 4px;
    font-size: 14px;
}

.contactLink .icon{
    margin-right: 5px;
    height: 18px;
    width: 18px;
}

.contactLink .icon svg{
    height: inherit;
    width: inherit;
}

.contactLink .link{
    text-decoration: none;
    font-size: 14px;
}

.unlockBadge{
    background-color: #fbbc04;
}

.addBadge{
    background-color: #FFF;
    color: #396a92 !important;
}

.unlockBadge, .addBadge{
    position: absolute;
    height: 15px !important;
    width: 15px !important;
    border-radius: 50%;
    color: black;
    padding: 3px;
    top: 16px;
    cursor: pointer;
    /*border: 1px solid white;*/
}
.unlockBadge.ltr, .addBadge.ltr{
    left: 20px;
}

.unlockBadge.rtl, .addBadge.rtl{
    left: 36px;
}
.contactContainer{
    display: inline;
    position: relative;
}