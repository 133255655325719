@media (min-width: 500px){
    .feedback-text{
        width: 356px;
    }
    .orphanApplyDialogPaper{
        padding: 16px 8px 24px;
    }
}
@media (max-width: 499px){
    .feedback-text{
        width: 100%;
    }
    .feedback-dialog-paper{
        margin: 16px !important;
        width: calc(100% - 32px);
    }
    .orphanApplyDialogPaper{
        margin: 16px !important;
        padding: 0px 0 8px;
    }
}
@media (min-width: 600px){
    .orContainer{
        height: calc(100% - 8px);
        position: relative;
        margin: 4px 0;
        cursor: default;
    }
    .orContainer .verticalLine{
        height: 100%;
        width: 1px;
        margin: auto;
        background-color: rgba(0, 0, 0, 0.12);
    }
    .orContainer .orLabel{
        width: fit-content;
        position: absolute;
        top: calc(50% - 20px);
        padding: 8px;
        left: calc(50% - 20.1px);
        background-color: white;
        font-weight: 700;
    }
}
@media (max-width: 599px){
    .orContainer{
        position: relative;
        cursor: default;
    }
    .orContainer .verticalLine{
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.12);
        margin: 18px 0;
    }
    .orContainer .orLabel{
        width: fit-content;
        position: absolute;
        padding: 8px;
        top: -18px;
        background-color: white;
        left: calc(50% - 20px);
        font-weight: 700;
    }
}
.orphanApplyDialogPaper{
    width: 750px;
    max-width: initial !important;
}
.orphanApplyDialogPaper .options{
    direction:rtl;
    margin-top: 16px;
}
.orphanApplyDialogPaper .options .title{
    font-weight: 700;
}
.orphanApplyDialogPaper .options img{
    border-radius: 50%;
    margin: 16px
}
.orphanApplyDialogPaper .options .textField{
    margin: 8px 0;
}
.orphanApplyDialogPaper .options .upload{
    margin: 8px 0;
}
.orphanApplyDialogPaper .terms{
    margin: 8px 0;
    display: block;
}
.orphanApplyJob{
    position: relative;
    left: -16px;
    margin-bottom: 8px;
}
.confirmOrphanApply .subtitle1{
    line-height: 24px;
    font-weight: 700;
    padding-bottom: 12px;
}
.confirmOrphanApply .passwordForm{
    max-width: 250px;
    margin:auto;
    margin-bottom:16px;
}
.confirmOrphanApply .terms{
    margin-top: 16px;
    display: block;
}
.terms a{
    color: #396a92;
}
.alertPaper{
    min-width: 200px;
}