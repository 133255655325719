.searchBox{
    margin: 10px;
    display: flex;
}    

.searchTextContainer{
    float: left;
    width: calc(100% - 84px);
    padding: 5px 10px;
}

.searchTextContainer .keywords{
    height: 26px;
    overflow: hidden;
}

.searchTextContainer .keywords.placeHolder{
    font-size: 16px;
}

.searchTextContainer .location{
    font-size: 14px
}
.searchTextContainer .location.placeHolder, .searchTextContainer .keywords.placeHolder{
    color: #bcbcbc
}

.searchButton{
    height: 42px !important;
    width: 42px !important;
}

.searchButtonWithText{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.searchSection{
    position: absolute;
    width: 100%;
}

#keyWords, #email, .locationField input, .LocationFieldMain input {
    font-size: 16px;
    padding: 10px !important;
    width: 300px;
    height: 23px;
}

.LocationFieldMain button {
    z-index: 10;
}

.LocationFieldMain div:first-child, .locationField div:first-child{
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
}

.nearLabel{
    margin: 8px;
}

.searchDialogContent{
    padding: 10px !important;
}

@media (max-width: 768px){
    .searchBox{
        margin-top: 68px;
    }
    .keywordsField{
        margin-bottom: 10px !important;
    }
    .locationField{
        margin-bottom: 16px !important;
    }
    .searchSectionContent {
        padding: 20px 24px;
    }
}
@media (min-width: 769px){
    .searchSection.centered{
        top: calc(30% - 8px);
        text-align: center;
    }

	.searchBox{
        margin-top: 76px;
    }    

    .keywordsField{
        vertical-align: middle !important;
        width: 300px;
    }

    .searchButtonD {
        margin-left: 8px !important;
        margin-right: 8px !important;
        height: 43px;
        bottom: 0px;
    }
    .locationField{
        display: inline-flex;
    }
    .locationField div:first-child{
        width: 300px;
    }
    .searchSectionContent {
        padding: 20px 16px;
    }
    .locationValueContainer{
        min-width: 230px;
    }
}