@media (max-width: 768px){
    .clLogo{
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
    .clLogo img{
        max-width: 38px;
        max-height: 38px;
    }
    .clInfo{
        margin-left: 56px;
    }
    .logoSkeleton{
        height: 40px !important;
        width: 40px !important;
    }
}
@media (min-width: 769px){
    .clLogo{
        width: 56px;
        height: 56px;
        margin-left: 24px;
        font-size: 28px;
    }
    .clLogo img{
        max-width: 54px;
        max-height: 54px;
    }
    .clInfo{
        margin-left: 80px;
    }
    .cl .clAvatar{
        height: 54px !important;
        width: 54px !important;
    }
    .logoSkeleton{
        height: 56px !important;
        width: 56px !important;
    }
}
.logoSkeleton{
    margin: 16px;
    position: absolute !important;
    border-radius: 4px;
}
.listActions {
    text-align: right;
    padding: 0 8px;
    border-top: 1px solid #e1e1e1;
}
.cl{
	cursor: pointer;
    background-color: #fafafa;
    outline: 0;
    display: inline-block;
    padding: 0 0 0 0;
    vertical-align: top;
    width: 100%;
    position: relative;
	border-top: 1px solid rgba(0,0,0,.10);
}
.cl.selected, .cl:hover{
	background-color: #eeeeee
}
.clLogo{
    background: white;
    display: inline;
    margin: 16px; 
    align-items: center;
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    color: #FFFFFF;
    text-align: center;
    line-height: 2em;
    border: 1px solid rgba(0,0,0,0.10);
    box-sizing: border-box;
    border-radius: 4px;
}
.clLogo img{
    border-radius: 4px;
}
.clInfo{
    padding: 13px 16px 11px;
}
.bookmarkIcon{
    float: right;
    padding-top: 5px;
    padding-right: 5px;
}
.clTitle{
	font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #222;
    text-transform: capitalize; 
}
.clCompany{
	font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #222;
    text-transform: capitalize;
}
.clLocation{
	font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.54);
    text-transform: capitalize;
}
.clJobMoreInfoSection{
    font-size: 12px;
    padding: 10px 0 0 0;
    line-height: 1.5em;
    color: rgba(0, 0, 0, 0.54);
}
.jobMoreInfo{
	display: inline-block;
}
.jobMoreInfo.rtl{
    margin-left: 8px;
}
.jobMoreInfo.ltr{
    margin-right: 8px;
}
.jobMoreInfo .jobMoreInfoIcon{
	height: 18px;
    line-height: 18px;
    width: 18px;
    display: inline-block;
    fill: currentColor;
    position: relative;
    vertical-align: middle;
}
.jobMoreInfo.rtl .jobMoreInfoIcon{
    padding-right: 4px;
}
.jobMoreInfo.ltr .jobMoreInfoIcon{
    padding-right: 4px;
}
.jobMoreInfo .jobMoreInfoText{
	vertical-align: middle;
}
.histogramObjectText{
    font-size: 14px
}

.reportInfo{
    border-top: 1px solid #e1e1e1;
    padding: 8px 16px;
    line-height: 1.5;
}

.histogramObjectText a{
    color: #396a92;
}
.expired {
    color: #db4437;
}