@font-face {
    font-family: almoni-neue-aaa;
    font-weight: 300;
    src: local('almoni-neue-aaa'), url(./fonts/almoni-neue-aaa-regular.otf) format('opentype');
}
@font-face {
    font-family: almoni-neue-aaa;
    font-weight: 100;
    src: local('almoni-neue-aaa'), url(./fonts/almoni-neue-aaa-thin.otf) format('opentype');
}
@font-face {
    font-family: almoni-neue-aaa;
    font-weight: 700;
    src: local('almoni-neue-aaa'), url(./fonts/almoni-neue-aaa-medium.otf) format('opentype');
}
@font-face {
    font-family: almoni-neue-aaa;
    font-weight: 900;
    src: local('almoni-neue-aaa'), url(./fonts/almoni-neue-aaa-bold.otf) format('opentype');
}
html, body {
    font-family: 'Roboto',arial,sans-serif;
    height: 100%;
    font-size: 100%;
    overflow-x: hidden;
}
body{
	background-color: #fafafa;
    margin: 0;
    padding: 0;
}
.hebrew-text{
    direction: rtl;
    text-align: right;
    font-family: "almoni-neue-aaa",Helvetica,Arial,sans-serif !important;
    font-size: 18px;
}

.hebrew-text *{
    font-family: "almoni-neue-aaa",Helvetica,Arial,sans-serif !important;
}
.hebrew-text .MuiInputLabel-formControl {
    right: 0;
    left: initial;
}
.hebrew-text .MuiInputLabel-root, .hebrew-text .MuiInputLabel-shrink {
    transform-origin: top right !important;
}
.hebrew-text .MuiFormHelperText-root{
    text-align: right;
}
.JobSwipeLogo{
    font-size: 2rem !important;
    font-weight: 300 !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important
}
.proLogo{
    margin-left: 3px !important;
    margin-top: 8px !important;
    color: #fbbc04;
    cursor: default;
}
.pageMainContainer{
    position: absolute;
    width: 100%;
    overflow: hidden;
}

.fullMaxWidth{
    max-width: 100% !important;
}

.actionText{
    cursor: pointer;
    color: #396a92;
}

.pac-container{
    z-index: 99999;
}

.headerTab{
    min-width: initial !important;
    padding: 6px 12px !important;
    transition-duration: 300ms;
}

.headerTab:not(.selected):hover {
    box-shadow: inset 0 -4px 0px 0px #396a92;
    opacity: 1;
}

.headerTab.afterUser{
    opacity: 1;
}
.headerTab.afterUser span{
    text-transform: capitalize;
    color: #396a92;
    font-size: 0.95rem;
}

.postNewJob .icon, .postNewJob .text{
    color: #396a92
}

/*.headerTab span{
    padding-right: 3px !important;
    padding-left: 3px !important;
}*/
.sortable-handler { touch-action: none; }

.mainTabIndicator{
    background-color: #396a92 !important;
    height: 4px !important;
}

button{
    box-shadow: none !important;
}

.mainBar{
    background-color: white !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 0px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important
}

.mainBar.testEnv{
    background-color: #e8f0fe !important;
}

.JSBadge {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    right: 9px;
    border: 1px solid white;
}
.terms a{
    color: #396a92;
    text-decoration: none;
}
.terms{
    display: block;
}
.fullWidthContainer{
    position: absolute;
    width: 100%
}

@media (max-width: 599px){
    .fullWidthContainer{
        top: 56px;
        height: calc(100% - 56px);
    }
}
@media (min-width: 600px){
    .fullWidthContainer{
        top: 65px;
        height: calc(100% - 65px);
    }
}
@media (max-width: 599px){
    .pageMainContainer{
        top: 56px;
    }
    .menuPaper{
        top: 57px !important;
    }
    .pageMainContainer.fullHeight{
        height: calc(100% - 56px)
    }
}
@media (min-width: 600px){
    .pageMainContainer{
        top: 65px;
    }
    .menuPaper{
        top: 65px !important;
    }
    .mainBar .centeredScreen{
        padding-right: 16px !important;
        padding-left: 16px !important;
    }
    .pageMainContainer.fullHeight{
        height: calc(100% - 65px)
    }
}
@media (min-width: 1200px){
    .centeredScreen, .pageMainContainer, .searchSection, .mainJobView {
        max-width: 1200px;
        margin: 0 calc((100% - 1200px)/2);
    }
}

@media (max-width: 768px){
    .userMenu{
        min-width: 250px !important;
    }
}
@media (min-width: 769px){
    .userMenu{
        display: flex;
        flex-direction: row-reverse;
    }
}
.userMenu{
    padding: 0
}

.selectedRadioIcon{
    font-weight: bold;
}

@keyframes scaleAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.8);
    }
    100% {
      transform: scale(1);
    }
}
  
.animated-element.active {
    animation: scaleAnimation 0.8s;
}
/*strong, .makeStyles-regularText-12{
    font-weight: normal !important;
}*/
