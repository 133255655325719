/*@media (max-width: 768px){
	.mainJobView {
        top: 128px;
        height: calc(100% - 176px);
    }
}
@media (min-width: 769px){
	.mainJobView {
        top: 238px;
        height: calc(100% - 238px);
    }
}*/


.mainJobView {
    display: flex; 
    position: absolute;
    width: 100%;
}