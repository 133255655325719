/*.public-DraftStyleDefault-block > div {
	margin: 0px !important;
}

.public-DraftStyleDefault-block {
    margin: 0px !important;
}*/

.rdw-editor-wrapper {
    border: 1px solid #f0f0f0;
}