.jobswipePro{
    text-align: center;
    margin: 16px;
    padding: 16px;
    cursor: pointer;
    margin-bottom: 0;
}

.jobswipeProCredits{
    text-align: center;
    padding: 16px;
    cursor: default;
}

.jobswipeProCreditsTitle{
    font-weight: bold !important;
}

.jobswipePro .JobSwipeLogo, .jobswipePro .proLogo{
    display: inline;
}
.jobswipePro .JobSwipeLogo{
    color: #424242;
}
.jobswipePro .mtext{
    font-size: 14px;
    line-height: 1.3125;
    color: #757575;
}

.crefitContainer{
    padding: 16px;
    padding-bottom: 0;
}