.landingPageContainer{
    width: 100%;
}
.landingPageImage{
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    z-index: 1;
}
.logos{
    display: inline-flex;
    width: 100%;
}
.logo{
    padding: 16px;
    margin: auto;
    max-width: 130px;
    max-height: 60px;
    display: inline-flex;
    height: 100%
}
.logo svg, .howWorks .icon svg{
    max-height: inherit;
    max-width: inherit;
    height: inherit;
    width: inherit;
}
.footer{
    text-align: center;
    box-sizing: border-box;
    background-color: #f5f6f7;
    border-top: 1px solid rgba(0,0,0,0.15);
}
.footer .termsArea{
    max-width: 1200px;
    margin: auto;
    text-align: left;
    padding: 16px 0;
}
.footer .termsArea a{
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    line-height: 1.33333;
    font-weight: 400;
    color: rgba(0,0,0,0.6);
    padding: 8px;
}
.landingPageSection{
    text-align: center;
    max-width: 1200px;
    box-sizing: border-box;
}
.lpsTitle{
    margin-bottom: 24px !important;
}
.howWorks .subtitle{
    text-transform: uppercase;
}
.footerCaption{
    position: absolute;
    color: white !important;
    padding: 5px 10px;
    z-index: 3;
    width: fit-content;
}
.footerCaption a{
    color: white;
    text-decoration: none;
}
.searchSectionMain{
    z-index: 2;
    box-sizing: border-box;
}
.landingPageContainer .title{
    font-weight: 700;
    color: white;
}
.searchSectionMain .label{
    letter-spacing: 1.2px;
    display: block;
}
.searchSectionMain .keywordsFieldMain, .searchSectionMain .LocationFieldMain{
    height: 55px;
}
.keywordsFieldMain input, .LocationFieldMain input{
    width: 100% !important;
    border-radius: 3px;
    z-index: 5;
}
.keywordsFieldMain fieldSet, .LocationFieldMain fieldSet{
    background-color: white;
}
.exploreJobs img{
    height: 100px;
    padding-bottom: 20px;
    box-sizing: border-box;
}
.exploreJobs .jobLink{
    cursor: pointer;
    position: relative;
    margin: 16px 0;
}
.exploreJobs .jobLink:after {
    content: "";
    position: absolute;
    top: 85px;
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    padding: 0 0 8px;
    width: 48px;
    border-bottom: 2px solid #ccc;
    transition: border-color .2s ease-in-out,padding .2s ease-in-out;
}
.exploreJobs .jobLink:hover:after {
    padding: 0 20px 8px;
    border-color: #396a92; 
}
/*.searchSectionMain .searchButtolp{
    margin-top: 16px;
}*/
@media (max-width: 768px){
    .landingPageSection{
        margin: 16px 16px 48px
    }
    .freeSpace{
        margin-top: 250px
    }
    .howWorks .icon {
        max-height: 60px;
        margin-bottom: 24px;
    }
    .searchSectionMain{
        padding: 16px;
    }
    .searchSectionMain .searchButtolp{
        width: 100%;
    }
    .footerCaption{
        /*bottom: 0;
        position: sticky;
        top: 100%;*/
        display: none;
    }
    .landingPageContainer .title{
        padding: 16px
    }
	.logos{
        display: none !important;
    }
    .landingPageImage{
        height: 100%;
        background-position: left;
        background-image: url(../assets/images/webp/landing_page_mobile.webp);
    }
    .no-webp.landingPageImage {
        background-image: url(../assets/images/landing_page_mobile.jpg);
    }
    @media (max-width: 599px){
        .landingPageContainer{
            margin-top: 56px;
        }
    }
    @media (min-width: 600px){
        .landingPageContainer{
            margin-top: 64px;
        }
    }
}
@media (min-width: 769px){ 
    .landingPageSection{
        padding: 72px;
        margin: auto
    }
    .footer{
        margin: auto
    }
    .howWorks .icon{
        max-height: 100px;
        margin-bottom: 16px
    }
    .searchSectionMain .keywordsFieldMain, .searchSectionMain .LocationFieldMain{
        max-width: 350px;
    }
    .searchSectionMain .label{
        margin-bottom: 16px;
        margin-top: 16px;
    }

    .landingPageContainer .title br{
        display: none;
    }
    .landingPageContainer{
        top: 64px;
        height: calc(100% - 64px);
        position: absolute;
    }
    .searchSectionMain{
        top: 137px;
        position: relative;
        padding-left: 16px;
    }
    .landingPageContainer .title, .searchSectionMain .label{
        color: white;
    }
    .landingPageContainer .title{
        top: 36px;
        padding-left: 16px;
        position: relative;
    }
    .logos{
        max-width: 1200px;
    }

    .landingPageImage{
        background-position: center;
        background-image: url(../assets/images/webp/landing_page.webp);
    }

    .no-webp.landingPageImage {
        background-image: url(../assets/images/landing_page.jpg);
    }

    @media (max-width: 1050px){
        .logos{
            overflow-x: auto;
        }
        .landingPageImage{
            height: calc(100% - 109px);
        }
        .footerCaption{
            top: calc(100% - 138px);
        }
    }
    @media (min-width: 1051px){
        .landingPageImage{
            height: calc(100% - 92px);
        }
        .footerCaption{
            top: calc(100% - 121px);
        }
    }
}

@media (min-width: 1200px){
    .searchSectionMain{
        left: calc((100% - 1200px)/2);
    }

    .logos{
        margin: 0 calc((100% - 1200px)/2);
    }

    .landingPageContainer .title{
        margin: 0 calc((100% - 1200px)/2);
    }
}