@media (max-width: 768px){
	.histogramSection{
        min-height: calc(100% - 65px);
        width: 100%;
    }
}
@media (min-width: 769px){
	.histogramSection{
        box-shadow: 0.5px 0 0 rgba(0,0,0,.1), -0.5px 0 0 rgba(0,0,0,.1);
        max-width: 368px;
        min-width: 368px;
        display: inline-block;
        margin-left: 16px;
        transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    }
    .histogramSection.hidden{
        /*max-width: 100px;
        min-width: 100px;*/
        display: none !important;
    }
}
.histTab {
    min-width: initial !important;
}
.histTab span{
    /*padding-right: 6px !important;
    padding-left: 6px !important;*/
    text-transform: capitalize;
}
.histogramSection{
    background-color: #fafafa; 
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.cards{
	margin: 0;
    padding: 0;
}
.histTitle{
    font-size: 16px;
    background-color: #fafafa;
    padding: 16px;
    border-top: 1px solid rgba(0,0,0,.10);
}
.hideListButton{
    padding: 0 !important;
    margin-right: 8px !important;
    transform: rotate(0deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    transform: rotate(180deg);
}
.hideListButton.on{
    transform: rotate(0deg);
}
.pagesContainer{
    background-color: rgb(250, 250, 250);
    border-top: 1px solid rgba(0,0,0,.10);
    padding: 5px 15px;
    justify-content: center;
}
.histogramSpinner{
    text-align: center;
    margin: 16px;
}
.searchCompanies{
    margin-bottom: 16px !important;
}
.searchCompanies input{
    padding: 10px;
}