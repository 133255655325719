.close-modal{
	border-radius: 50%;
    height: 24px;
    width: 24px;
    outline: none;
    padding: 8px;
    position: absolute;
    right: 16px;
    transition: background-color 0.2s;
    top: 13px;
    cursor: pointer;
    color: rgba(0,0,0,.87);
    z-index: 10;
}