@media (max-width: 768px){
	.filterSection {
        max-height: 48px;
        min-height: 48px;
        bottom: 0;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 -8px 24px 0 rgba(0, 0, 0, 0.24);
        overflow: auto;
        width:100%;
    }
    .filterTabs {
        background-color: #396a92;
        position: fixed;
        width: 100%;
    }
    .filterTagsSection{
        margin-top: 48px;
    }
    .filterSection.expanded{
        max-height: 200px;
        min-height: 200px;
    }

    .filterTab span{
        opacity: 0.8;
    }

    .filterTabsLabel, .filterTab span{
        font-size: 14px;
        color: #fff;
        text-transform: uppercase;
    }

    .filterTab.selected span{
        opacity: initial;
    }

    .filterTab.filtered span{
        color: #f4b400;
        opacity: initial;
    }

    .filterTab.filtered{
        opacity: 1;
    }

    .tabIndicator{
        background-color: #f4b400 !important;
    }

    .tabIndicator.filtered{
        background-color: #f4b400 !important;
    }

    .filterTabsLabel{
        padding: 16px 0;
        padding-left: 16px;
    }

    .filterTabsRow {
        margin-left: 100px;
    }

    .filterTagsSection {
        padding: 6px 16px 5px;
    }

    .clearFilter {
        color: #fff !important;
        background-color: rgba(255,255,255,.3) !important;
        font-weight: bold !important;
        font-size: 14px !important;
        position: relative;
        bottom: 8px;
    }

    .clearFilter svg{
        color: #fff !important;
    }
}
@media (min-width: 769px){
	.filterSection {
        max-height: 100px;
        min-height: 100px;
        top: 138px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        margin: 0 16px;
        width: calc(100% - 32px);
    }
    .filterSection:hover{
        max-height: 195px;
        overflow: auto;
    }

    .filterTab {
        min-width: initial !important;
    }
    
    /*.filterTab span {
        padding-right: 6px;
        padding-left: 6px;
    }*/
    
    .filterTabs {
        padding-right: 16px;
        padding-left: 16px;
    }

    .filterTabsLabel, .filterTab span{
        color: rgba(0,0,0,.54);
        font-size: 14px;
        text-transform: capitalize;
    }

    .filterTab.selected span{
        color: #396a92;
    }

    .filterTab.filtered span{
        color: #f09300;
    }

    .filterTabsLabel{
        padding: 16px 0;
    }

    .filterTabsRow {
        margin-left: 60px;
    }

    .filterTagsSection {
        padding: 6px 16px 5px;
        max-width: 1447px;
    }

    .clearFilter {
        color: #396a92 !important;
        background-color: #f5f5f5 !important;
    }

    .clearFilter svg{
        color: #396a92 !important;
    }

    .tabIndicator{
        background-color: #396a92 !important;
    }

    .tabIndicator.filtered{
        background-color: #f09300 !important;
    }
}
@media (min-width: 1200px){
    .filterSection {
        max-width: 1168px;
        margin: 0 calc((100% - 1168px)/2);
    }
}

.filterSection {
    background-color: #fff;
    position: absolute;
    z-index: 2;
    transition-duration: 0.6s;
}

.filterTabsLabel{
    position: absolute;
    cursor: default;
}

.clearFilter {
    font-weight: bold !important;
    font-size: 14px !important;
    position: relative;
    bottom: 8px;
}

.MuiTab-textColorPrimary-113.MuiTab-selected-115 {
    color: yellow !important;
}