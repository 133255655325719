/*@media (max-width: 599px){
    .paymentDialogPaper{
        padding: 0 8px;
    }
}
@media (min-width: 600px){
    .paymentDialogPaper{
        padding: 0 32px;
    }
}*/
.paymentDialogPaper{
    text-align: center;
    width: 100%;
    max-width: 400px !important;
    margin: 8px !important;
    max-height: 600px !important;
    height: calc(100% - 16px) !important;
    box-sizing: border-box;
}
.paymentDialogContainer{
    margin: auto;
    width:100%;
    margin-bottom: 0;
}
.paymentDialogPaper .pTitle{
    font-size: 32px;
    margin: 16px 0;
}
.paymentDialogPaper .pTitle .pro{
    color: #fbbc04;
    font-size: 24px;
}
.paymentDialogPaper .pTitle .for{
    display: inline-block;
    margin-left: 8px;
}
.feature{
    padding: 16px;
}
.feature .fTitle, .feature .fDescription{
    font-size: 18px;
}
.feature .fDescription {
    min-height: 45px;
}
.feature .fTitle{
    font-weight: 600;
    margin-bottom: 10px
}
.points{
    display: inline-flex;
    margin: auto;
    margin-top: 12px;
}
.points .point{
    cursor: pointer;
    padding: 4px;
    margin: auto 4px;
    background-color: #d9d9d9;
    border-radius: 100%;
}
.points .point.selected{
    background-color: #434343;
}
.points .prevIcon{
    margin-right: 16px;
    cursor: pointer;
}
.points .nextIcon{
    margin-left: 16px;
    cursor: pointer;
}
.paymentButtons{
    padding: 16px 36px 10px;
}
.paymentButtonProceed, .paymentButtonCancel{
    min-height: 40px;
    padding: 0 24px;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    border-style: none;
    outline: none;
    transition-property: background;
    transition-duration: .2s;
    border-radius: 100px;
    font-size: 15px;
    display: block;
    margin: auto;
    margin-top: 20px;
    font-weight: 600;
}
.paymentButtonProceed{
    background: #fbbc04;
    height: 50px;
}
.paymentButtonCancel{ 
    width: initial;
    background-color: white;
    color: #757575;
}
.options{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    margin-top: 32px;
}
.option{
    border: 3px solid #fff;
    border-radius: 10px;
    padding: 14px 4px;
    transition-property: color;
    transition-duration: .25s;
    flex-direction: column;
    flex-grow: 1;
    display: flex;
    cursor: pointer;
    background-clip: padding-box;
    color: #707d86;
    position: relative;
    max-width: 140px;
}
.option .month{
    font-size: 40px;
    font-weight: 500;
    line-height: 1;
    margin-top: 5px;
}
.option .monthLabel, .option .price, .option .save{
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 400;
}
.option .price b{
    font-size: 15px;
}
.option .save{
    color: #396a92;
    font-weight: 600;
    margin-bottom: 6px;
}
.option .topLabel{
    position: absolute;
    text-transform: uppercase;
    padding: 6px 10px;
    font-size: 7px;
    top: -8px;
    font-weight: 600;
    left: 0;
    right: 0;
    letter-spacing: 0.5px;
}
.option.selected{
    border-color: #396a92;
    background-color: #f5f7fa;
}
.option.selected .month, .option.selected .monthLabel{
    color: #424242;
}
.option.selected .monthLabel{
    font-weight: 600;
}
.option.selected .topLabel{
    color: white;
    background-color: #396a92;
    border-radius: 10px 10px 0 0;
    font-size: 11px;
    top: -16px;
    font-weight: 600;
    margin: 0 -3px;
}