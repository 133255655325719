.mobileHeader{
    height: 56px;
    position: fixed;
    top: 0;
    z-index: 125;
    width: 100%;
}
.mobileHeader.withShadow{
    box-shadow: rgba(0, 0, 0, 0.26) 0px 2px 5px;
}
.toHistogram{
    color: #fff;
    transition-duration: 0.3s;
    transition-property: opacity;
    display: inline-block;
    opacity: 1;
    vertical-align: middle;
    position: absolute;
    padding: 16px;
    cursor: pointer;
}
.toHistogram span:first-child{
	display: inline-block;
    fill: currentColor;
    height: 24px;
    line-height: 24px;
    position: relative;
    width: 24px;
}
.backToJobs {
    bottom: 6px;
    display: inline-block;
    position: relative;
    margin-left: 5px;
    font-size: 16px;
}