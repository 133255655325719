.link {
    color: #396a92;
    cursor: pointer;
}
.loginDialog{
    max-width: 330px !important;
}
.greyText{
    text-align: center;
    color: #7f7f7f;
}
.close-modal{
	border-radius: 50%;
    height: 24px;
    width: 24px;
    outline: none;
    padding: 8px;
    position: absolute;
    right: 16px;
    transition: background-color 0.2s;
    top: 13px;
    cursor: pointer;
    color: rgba(0,0,0,.87);
}
.forgotPassword {
    margin-bottom: 15px;
    margin-top: 5px;
    text-align: right;
}
.loginFooterDivider {
    margin-bottom: 20px !important;
    margin-top: 15px !important;
}
.loginPaper{
    max-width: 350px !important;
    padding-bottom: 8px !important;
    margin: 8px !important;
}
.facebookLoginButton, .linkedInLoginButton{
    text-transform: none !important;
    margin: 2px 0 !important;
    height: 50px;
    font-size: 16px !important;
    padding-left: 48px !important;
}
.facebookLoginButton svg, .linkedInLoginButton svg{
    height: 32px;
    width: 32px;
    position: absolute;
    left: 8px;
}
.facebookLoginButton{
    background: rgb(59, 89, 152) !important;
}
.facebookLoginButton:hover{
    background: rgb(41, 62, 105) !important;
}
.linkedInLoginButton{
    background: rgb(26, 129, 185) !important;
}
.linkedInLoginButton:hover{
    background: rgb(7, 112, 169) !important;
}