.profileSection{
    margin: 16px;
    padding: 16PX;
    position: relative;
}

.profileListSection{
    position: relative;
}

.sectionTitle {
    font-size: 18px;
    margin-bottom: 5px;
    text-transform: capitalize;
    font-weight: 700;
}

.sectionText{
    color: rgba(0, 0, 0, 0.54);
    padding: 5px 0;
    font-size: 0.8rem !important;
}

.profileActions {
    position: absolute !important;
    right: 0;
    top: 0;
}

.profileButtons {
    text-align: right;
    margin-top: 20px;
}

.profileFieldText{
    margin-top: 5px;
}

.profileField{
    margin-top: 10px;
    white-space: pre-line;
}

.profileField .text{
    font-size: 14px;
    line-height: 1.5em;
    color: rgba(0,0,0,0.87);
}

.profileEditText{
    cursor: pointer;
    color: #396a92
}

.profileTag{
    margin: 3px !important;
    direction: ltr;
}

.profileField.list .title{
    text-transform: capitalize;
    font-size: 15px;
    padding-top: 15px;
    font-weight: bold;
}

.profileField.list .underTitle{
    padding-top: 18px;
    text-transform: capitalize;
    color: #757575;
}

.profileField.list .location{
    padding-top: 8px !important;
    color: #757575;
}

.profileField.list .description{
    padding-top: 8px;
    color: #757575;
}

.profileField.list .dates{
    position: absolute;
    right: 15px;
    font-weight: normal;
    color: #757575;
    top: 50px;
    max-width: 25%;
    text-align: right;
}

.profileField.list{
    margin-top: 0;
    margin-bottom: 10px;
}

.profileListSection form{
    margin: 10px 0
}

.profileField .icon{
    display: inline;
}

.profileLink{
    display: flex;
}

.profileLink .icon{
    margin-right: 5px
}

.profileLink .link{
    margin-top: 4px
}

.profileBasic .name{
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 1px;
}

.profileBasic .title{
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
}

.profileBasic .location{
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: rgba(0,0,0,.54);
}

.profileTag .star, .inputTag{
    color: #bdbdbd !important;
}
.profileTag .selected, .inputTag.selected{
    color: #396a92 !important;
}

.profileAvatar{
    margin-top: 13px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    height: 60px;
    width: 60px;
}

.profileAvatar .clAvatar{
    width: 60px;
    height: 60px;
    font-size: 27px;
}

.profileAvatar .change{
    background: rgba(0,0,0,.54);
    bottom: 0;
    color: #fff;
    font-size: 9px;
    font-weight: bold;
    left: 0;
    line-height: 5px;
    position: absolute;
    padding: 7px 0;
    text-align: center;
    width: 60px;
    -webkit-tap-highlight-color: transparent;
    white-space: nowrap;
}

.resumeSection{
    border-top: 1px solid #ebebeb;
    padding: 16px 0;
}

@media (min-width: 769px){
    .editProfile {
        width: calc(100% - 384px);
        margin-bottom: 4px;
        max-width: 1027px;
        overflow-y: auto;
        min-height: calc(100% - 36px);
    }

    .profileSection{
        padding: 32PX;
    }

    .profileDoneEdit{
        margin: 16px 12px 0 24px !important
    }

    .profileDoneEdit button{
        margin: 0 8px;
    }
    .profilePromote{
        margin-top: 16px !important;
    }
}

@media (max-width: 768px){
    .editProfile {
        width: 100%;
        padding-top: 54px;
        background-color: #fafafa;
        padding-bottom: 16px;
    }

    .profileSection{
        padding: 16PX;
    }

    .profileDoneEdit{
        margin: 16px 12px 0 16px !important
    }

    .profilePromote{
        margin-top: 16px !important;
    }
}